<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-database-cog"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">Bancos de Dados Oracle</div>
      </template>

      <v-btn
        v-if="accessReleased('ORACLE_SERVIDORES_BANCOS_DADOS_ADICIONAR')"
        id="btn-novo-servidor"
        color="success"
        dark
        elevation="1"
        class="mt-5"
        style="float: left"
        relative
        text
        medium
        @click="$router.push('edit')"
      >
        <v-icon
          left
          size="30px"
        >
          mdi-plus-circle
        </v-icon>
        Novo Servidor
      </v-btn>

      <v-text-field
        id="servidores-search-search"
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 250px"
      />

      <v-divider class="mt-10" />

      <v-data-table
        class="servidores-table"
        :headers="[
          {
            text: '#',
            value: 'id'
          },
          {
            text: 'Servidor',
            value: 'nome'
          },
          {
            text: 'Status',
            value: 'status'
          },
          {
            sortable: false,
            text: 'Ações',
            value: 'actions',
            class: 'pl-4'
          }
        ]"
        :items="items"
        :search.sync="search"
        :mobile-breakpoint="800"
        :loading="loading"
        :hide-default-header="loading"
        :hide-default-footer="loading"
        no-data-text="Nenhum servidor de banco de dados cadastrado"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | addLeadingZero }}
        </template>
        <template v-slot:[`item.nome`]="{ item }">
          <span :title="item.tnsConfig">
            {{ item.nome }}
          </span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <servidor-status :status="item.ativo" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-if="accessReleased('ORACLE_SERVIDORES_BANCOS_DADOS_EDITAR')"
            id="action-edit"
            color="gray"
            min-width="0"
            class="px-1 ml-n1"
            fab
            icon
            x-small
            @click="$router.push(`edit/${item.id}`)"
            :disabled="item.ativo === 'Inativo'"
          >
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                id="action-more"
                color="white"
                class="ml-1"
                height="22px"
                width="22px"
                fab
                x-small
                elevation="1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="openDialogChangeStatus(item)">
                <v-list-item-title>
                  <v-icon
                    small
                    v-if="item.ativo === 'Ativo'"
                  >
                    mdi-database-off-outline
                  </v-icon>
                  <v-icon v-else> mdi-database-check-outline </v-icon>
                  <span v-if="item.ativo === 'Ativo'"> Inativar </span>
                  <span v-else> Ativar </span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  (dialogCreationLog = true),
                    (dialogCreationLogData = {
                      username: item.usuario,
                      created: item.created
                    })
                "
              >
                <v-list-item-title>
                  <v-icon small> mdi-table-search </v-icon>
                  Log
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`footer.page-text`]>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="$emit('rechargeTable')"
                >mdi-refresh</v-icon
              >
            </template>
            <span>Clique aqui para recarregar os bancos de dados Oracle</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
    <dialog-change-status
      :dialog="dialogChangeStatus"
      @update:dialog="dialogChangeStatus = $event"
      @submitTriggered="changeStatus(dialogChangeStatusData.id)"
    >
      <template slot="title">
        <span v-if="dialogChangeStatusData.ativo === 'Ativo'"> Inativar </span>
        <span v-else> Ativar </span> servidor #{{
          dialogChangeStatusData.id | addLeadingZero
        }}
      </template>
      <template slot="body">
        Tem certeza que deseja
        <span v-if="dialogChangeStatusData.ativo === 'Ativo'"> inativar </span>
        <span v-else> ativar </span>
        o servidor <strong>{{ dialogChangeStatusData.nome }}</strong
        >?
      </template>
    </dialog-change-status>
  </v-container>
</template>

<script>
import ServidoresBancosDadosOracleService from '@/services/ServidoresBancosDadosOracleService';
import ServidorStatus from '@/components/general/ServidorStatus.vue';
import DialogCreationLog from '@/components/general/DialogCreationLog.vue';
import DialogChangeStatus from '@/components/general/DialogChangeStatus.vue';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  mixins: [profilePermissionsMixin],
  name: 'ServidoresBancosDadosListar',
  components: {
    ServidorStatus,
    DialogCreationLog,
    DialogChangeStatus
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    search: '',
    dialogCreationLog: false,
    dialogCreationLogData: {},
    dialogChangeStatus: false,
    dialogChangeStatusData: {
      id: undefined,
      nome: undefined
    }
  }),
  methods: {
    openDialogChangeStatus(item) {
      this.dialogChangeStatusData = item;
      this.dialogChangeStatus = true;
    },
    changeStatus(id) {
      ServidoresBancosDadosOracleService.changeStatus(id)
        .then(() => {
          this.$toast.success(
            'Status do servidor atualizado com sucesso.',
            '',
            { position: 'topRight' }
          );
          this.$emit('status:changed');
        })
        .catch((error) => {
          this.$toast.error('Erro ao atualizar o status do servidor.', '', {
            position: 'topRight'
          });
          console.error(error);
        });
      this.dialogChangeStatus = false;
    }
  }
};
</script>
