var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-database-cog","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("Bancos de Dados Oracle")])]},proxy:true}])},[(_vm.accessReleased('ORACLE_SERVIDORES_BANCOS_DADOS_ADICIONAR'))?_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"id":"btn-novo-servidor","color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('edit')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Novo Servidor ")],1):_vm._e(),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search",staticStyle:{"max-width":"250px"},attrs:{"id":"servidores-search-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"servidores-table",attrs:{"headers":[
        {
          text: '#',
          value: 'id'
        },
        {
          text: 'Servidor',
          value: 'nome'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          sortable: false,
          text: 'Ações',
          value: 'actions',
          class: 'pl-4'
        }
      ],"items":_vm.items,"search":_vm.search,"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-data-text":"Nenhum servidor de banco de dados cadastrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("addLeadingZero")(item.id))+" ")]}},{key:"item.nome",fn:function(ref){
      var item = ref.item;
return [_c('span',{attrs:{"title":item.tnsConfig}},[_vm._v(" "+_vm._s(item.nome)+" ")])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('servidor-status',{attrs:{"status":item.ativo}})]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(_vm.accessReleased('ORACLE_SERVIDORES_BANCOS_DADOS_EDITAR'))?_c('v-btn',{staticClass:"px-1 ml-n1",attrs:{"id":"action-edit","color":"gray","min-width":"0","fab":"","icon":"","x-small":"","disabled":item.ativo === 'Inativo'},on:{"click":function($event){return _vm.$router.push(("edit/" + (item.id)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"id":"action-more","color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openDialogChangeStatus(item)}}},[_c('v-list-item-title',[(item.ativo === 'Ativo')?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-database-off-outline ")]):_c('v-icon',[_vm._v(" mdi-database-check-outline ")]),(item.ativo === 'Ativo')?_c('span',[_vm._v(" Inativar ")]):_c('span',[_vm._v(" Ativar ")])],1)],1),_c('v-list-item',{on:{"click":function($event){(_vm.dialogCreationLog = true),
                  (_vm.dialogCreationLogData = {
                    username: item.usuario,
                    created: item.created
                  })}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-table-search ")]),_vm._v(" Log ")],1)],1)],1)],1)]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('rechargeTable')}}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar os bancos de dados Oracle")])])]},proxy:true}],null,true)})],1),_c('dialog-creation-log',{attrs:{"dialog":_vm.dialogCreationLog,"item":_vm.dialogCreationLogData},on:{"update:dialog":function($event){_vm.dialogCreationLog=$event}}}),_c('dialog-change-status',{attrs:{"dialog":_vm.dialogChangeStatus},on:{"update:dialog":function($event){_vm.dialogChangeStatus = $event},"submitTriggered":function($event){return _vm.changeStatus(_vm.dialogChangeStatusData.id)}}},[_c('template',{slot:"title"},[(_vm.dialogChangeStatusData.ativo === 'Ativo')?_c('span',[_vm._v(" Inativar ")]):_c('span',[_vm._v(" Ativar ")]),_vm._v(" servidor #"+_vm._s(_vm._f("addLeadingZero")(_vm.dialogChangeStatusData.id))+" ")]),_c('template',{slot:"body"},[_vm._v(" Tem certeza que deseja "),(_vm.dialogChangeStatusData.ativo === 'Ativo')?_c('span',[_vm._v(" inativar ")]):_c('span',[_vm._v(" ativar ")]),_vm._v(" o servidor "),_c('strong',[_vm._v(_vm._s(_vm.dialogChangeStatusData.nome))]),_vm._v("? ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }