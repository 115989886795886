<template>
  <div>
    <span
      class="size-status"
      style="color: #53db60"
      v-if="status == 'Ativo'"
    >
      <v-icon
        small
        color="#53db60"
        class="pb-1 icon-status"
      >
        mdi-database-check-outline
      </v-icon>
      Ativo
    </span>
    <span
      class="size-status"
      style="color: gray"
      v-else
    >
      <v-icon
        small
        color="gray"
        class="pb-1 icon-status"
      >
        mdi-database-off-outline
      </v-icon>
      Inativo
    </span>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true
    }
  }
};
</script>

<style>
.size-status {
  font-size: 0.95rem !important;
  font-weight: normal !important;
}

.size-status .v-icon {
  font-size: 0.9rem !important;
}

.icon-status {
  margin-right: 1.7px !important;
}
</style>
