import APIService from './APIService';

export default {
  getById(id) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(`/oracle/servidores-bancos-dados/get-by-id/${id}`)
        .then((response) => {
          const { data: servidor } = response.data;
          resolve(servidor);
        })
        .catch((error) => reject(error));
    });
  },
  getServidores() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get('/oracle/servidores-bancos-dados/get-all')
        .then((response) => resolve(response.data.data))
        .catch((error) => reject(error));
    });
  },
  save(postData) {
    return APIService.apiCall().post(
      '/oracle/servidores-bancos-dados/save',
      JSON.stringify(postData)
    );
  },
  changeStatus(id) {
    return APIService.apiCall().patch(
      `/oracle/servidores-bancos-dados/change-status/${id}`
    );
  },
  testConnection(postData) {
    return APIService.apiCall().post(
      '/oracle/servidores-bancos-dados/test-connection',
      JSON.stringify(postData)
    );
  },
  showDatabases(postData) {
    return APIService.apiCall().post(
      `/oracle/servidores-bancos-dados/show-databases`,
      JSON.stringify(postData)
    );
  },
  getResumoEntidadesImportadas(id, database) {
    return APIService.apiCall().get(
      `/oracle/servidores-bancos-dados/get-resumo-entidades-importadas/${id}/${database}`
    );
  },
  getStatusCamposCodBncEntidades(
    servidorId, 
    bdgdVersaoId, 
    entidades
  ) {
    return APIService.apiCall().post(
      '/oracle/servidores-bancos-dados/get-status-campos-cod-bnc-entidades',
      JSON.stringify({ 
        servidorId,
        bdgdVersaoId,
        entidades 
      })
    );
  },
  baixarLinhasEntidadeComCodBncNulos(
    servidorId, 
    bdgdVersaoId, 
    entidade, 
    config = {}
  ) {
    return APIService.apiCall().post(
      '/oracle/servidores-bancos-dados/baixar-linhas-entidade-com-cod-bnc-nulos',
      JSON.stringify({ 
        servidorId,
        bdgdVersaoId,
        entidade 
      }),
      { responseType: 'blob', ...config }
    );
  },
};
